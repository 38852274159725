.dashboardLayout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f4f6f8;
}

.mainContent {
    display: flex;
    flex-grow: 1;
}

.mainContainer {
    flex-grow: 1;
    padding: 30px;
    background-color: #f4f6f8;
    transition: all 0.3s ease;
}

.pageTitle {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 30px;
    font-weight: 700;
    letter-spacing: 1px;
}

.widgetGrid {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
}

.widgetColumn {
    flex: 1;
    min-width: 300px;
}

@media (max-width: 768px) {
    .mainContent {
        flex-direction: column;
    }

    .widgetGrid {
        flex-direction: column;
    }

    .widgetColumn {
        min-width: 100%;
    }
}