.container {
    margin: 0 auto;
    padding: 30px 20px;
    margin-bottom: 70px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.title {
    font-size: 2.2rem;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 40px;
    font-weight: 700;
    text-transform: uppercase;
}

.featureList {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
}

.featureItem {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.featureItem:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
    background-color: #f9fbfd;
}

.featureTitle {
    font-size: 1.6rem;
    color: #34495e;
    margin-bottom: 10px;
    font-weight: 600;
}

.featureDescription {
    font-size: 1.1rem;
    color: #7f8c8d;
    line-height: 1.4;
}

@media (min-width: 768px) {
    .featureList {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }
}

@media (max-width: 768px) {
    .featureItem {
        padding: 15px;
    }

    .featureTitle {
        font-size: 1.4rem;
    }

    .featureDescription {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .featureDescription {
        font-size: 0.95rem;
        line-height: 1.3;
    }
}
