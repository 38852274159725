.widgetContainer {
    perspective: 1000px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.widget {
    background: linear-gradient(145deg, #f0f4f8, #ffffff);
    border-radius: 20px;
    box-shadow: 
        10px 10px 20px rgba(0, 0, 0, 0.075),
        -10px -10px 20px rgba(255, 255, 255, 0.9);
    padding: 30px;
    transition: all 0.3s ease;
    transform-style: preserve-3d;
    text-align: center;
}

.widget:hover {
    transform: rotateX(1deg) rotateY(-1deg);
    box-shadow: 
        15px 15px 25px rgba(0, 0, 0, 0.1),
        -15px -15px 25px rgba(255, 255, 255, 0.95);
}

.title {
    font-size: 1.8rem;
    color: #2c3e50;
    margin-bottom: 20px;
    letter-spacing: 1px;
}

.studentCountContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.studentCount {
    font-size: 4rem;
    font-weight: 700;
    color: #3498db;
    margin-bottom: 10px;
}

.studentSubtext {
    font-size: 1rem;
    color: #7f8c8d;
}

.loadingText {
    font-size: 1.5rem;
    color: #3498db;
}