.benefitsContainer {
    margin: 0 auto;
    padding: 30px 20px;
    margin-bottom: 70px;
}

.title {
    font-size: 2.2rem;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 30px;
}

.benefitList {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.benefitItem {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
}

.benefitItem:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
}

.benefitTitle {
    font-size: 1.5rem;
    color: #34495e;
    margin-bottom: 10px;
    font-weight: 600;
}

.benefitDescription {
    font-size: 1.1rem;
    color: #7f8c8d;
}

@media (min-width: 768px) {
    .benefitList {
        grid-template-columns: repeat(2, 1fr);
    }
}
