.modal {
    position: fixed;
    inset: 0;
    z-index: 101;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.modalContent {
    background-color: white;
    padding: 24px;
    border-radius: 16px;
    max-width: 600px;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.historyTable {
    width: 100%;
    border-collapse: collapse;
}

.historyTable th,
.historyTable td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.historyTable th {
    background-color: #f0f0f0;
    font-weight: bold;
}

.noHistory {
    text-align: center;
    color: #666;
    padding: 20px;
}

.viewHistoryButton {
    background-color: #10b981;
    color: white;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
}